import FormFieldText from '../fields/FormFieldInput'
import FormFieldCheckbox from '../fields/FormFieldCheckbox'
import FormFieldRadioGroup from '../fields/FormFieldRadioGroup'
import FormFieldUploadImage from '../fields/FormFieldUploadImage'
import { formFieldUploadImageMocks } from '../fields/FormFieldUploadImage/mocks'
import FormFieldPhone from '../fields/FormFieldPhone'

import { FormikFieldProps } from './index'

const commonProps = {
  name: 'fieldName',
  label: 'Label',
  placeholder: 'Placeholder',
  required: true,
}

const basic: FormikFieldProps = {
  ...commonProps,
  Component: FormFieldText,
}

const checkbox: FormikFieldProps = {
  ...commonProps,
  Component: FormFieldCheckbox,
  type: 'checkbox', // needed for checkbox field
}

const radioGroup: FormikFieldProps = {
  ...commonProps,
  Component: FormFieldRadioGroup,
  options: [
    {
      label: 'Option 1',
      value: 'option-1',
    },
    {
      label: 'Option 2',
      value: 'option-2',
    },
    {
      label: 'Option 3',
      value: 'option-3',
    },
  ],
}

const phone: FormikFieldProps = {
  ...commonProps,
  Component: FormFieldPhone,
}

const uploadImage: FormikFieldProps = {
  ...formFieldUploadImageMocks.basic,
  ...commonProps,
  Component: FormFieldUploadImage,
}

export const formikFieldMocks = {
  basic,
  checkbox,
  radioGroup,
  phone,
  uploadImage,
}
