import React, { FC, useRef } from 'react'

import { ActionButtonProps } from '../../../atoms/ActionButton'
import { ActionButtonVariant } from '../../../atoms/ActionButton/styled'

import * as SC from './styled'

export type FormFieldUploadImageProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  className?: string
  id?: string
  name: string
  value?: string
  label?: string
  required?: boolean
  disabled?: boolean
  error?: string
  uploadButton: ActionButtonProps
  deleteButton: ActionButtonProps
  emptyText: string
  onChange?: (event: { target: { name: string; value?: string } }) => void
}

const FormFieldUploadImage: FC<FormFieldUploadImageProps> = ({
  className,
  id,
  name,
  value,
  label,
  required,
  error,
  uploadButton,
  deleteButton,
  emptyText,
  onChange,
  ...inputProps
}) => {
  const $input = useRef<HTMLInputElement>(null)
  return (
    <SC.Container className={className} $isDisabled={inputProps.disabled}>
      <SC.Label htmlFor={id} required={required} error={!!error}>
        {label}
      </SC.Label>
      <input
        ref={$input}
        hidden
        accept="image/*"
        type="file"
        name={name}
        onChange={(e) => {
          onChange?.({
            target: {
              name: name!,
              value: e.currentTarget.files?.[0]
                ? URL.createObjectURL(e.currentTarget.files?.[0])
                : undefined,
            },
          })
        }}
        {...inputProps}
      />
      {value ? (
        <SC.ImagePreview src={value} />
      ) : (
        <SC.EmptyText>{emptyText}</SC.EmptyText>
      )}
      <SC.UploadButton
        {...uploadButton}
        disabled={inputProps.disabled}
        variant={ActionButtonVariant.secondary}
        onClick={(e: any) => {
          uploadButton.onClick?.(e)
          $input.current?.click()
        }}
        $isDisabled={inputProps.disabled}
        $hasValue={!!value}
      >
        {uploadButton.text}
      </SC.UploadButton>
      <SC.DeleteButton
        {...deleteButton}
        disabled={inputProps.disabled}
        // variant="dangerLight"
        onClick={() => {
          onChange?.({
            target: {
              name: name!,
              value: undefined,
            },
          })
        }}
        $isDisabled={inputProps.disabled}
        $hasValue={!!value}
      />
    </SC.Container>
  )
}

export default FormFieldUploadImage
