import React, { FC, InputHTMLAttributes } from 'react'

import { Icons } from '../../../atoms/Icon'

import * as SC from './styled'

export type FormFieldRadioGroupProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value'
> & {
  className?: string
  label?: string
  name: string
  value?: string
  options: { label: string; value: string; disabled?: boolean }[]
  required?: boolean
  disabled?: boolean
  help?: string
  error?: string
  hasError?: boolean
}

const FormFieldRadioGroup: FC<FormFieldRadioGroupProps> = ({
  className,
  name,
  label,
  options,
  required,
  disabled,
  help,
  error,
  hasError,
  ...inputProps
}) => {
  return (
    <SC.Container className={className} $isDisabled={disabled}>
      {label && (
        <SC.Label required={required} error={!!error || hasError}>
          {label}
        </SC.Label>
      )}
      <SC.Options>
        {options?.map((option) => (
          <SC.Option
            key={option.value}
            $isOptionDisabled={option.disabled}
            $isInputDisabled={disabled}
          >
            <SC.OptionRadio
              {...inputProps}
              type="radio"
              name={name}
              value={option.value}
              disabled={disabled || option.disabled}
              checked={option.value === inputProps.value ? true : false}
            />
            <SC.StyledIcon icon={Icons.check} />
            {option.label}
          </SC.Option>
        ))}
      </SC.Options>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldRadioGroup
