import React, { FC, ReactNode } from 'react'

import * as SC from './styled'

export type FormLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  className?: string
  required?: boolean
  error?: boolean
  children?: ReactNode
}

const FormLabel: FC<FormLabelProps> = ({ error, children, ...props }) => {
  return (
    <SC.Label {...props} $error={error}>
      {children}
    </SC.Label>
  )
}

export default FormLabel
