import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'
import Icon from '../../../atoms/Icon'

export const Container = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-flow: column;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

export const Label = styled(FormLabel)`
  margin-bottom: 0.5rem;
`

export const Options = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Option = styled.label<{
  $isOptionDisabled?: boolean
  $isInputDisabled?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  ${({ theme }) => theme.textStyles.textMedium};
  cursor: pointer;
  ${({ $isOptionDisabled }) =>
    $isOptionDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
  ${({ $isInputDisabled }) =>
    $isInputDisabled &&
    css`
      opacity: 1;
      cursor: not-allowed;
    `}

  & input:checked ~ svg {
    background-color: ${({ theme }) => theme.colors.arapawa};

    & use {
      color: white;
    }
  }

  & input:checked ~ svg use {
    visibility: visible;
  }
`

export const StyledIcon = styled(Icon)`
  padding: 0px;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.arapawa};
  border-radius: 0.3rem;

  & use {
    visibility: hidden;
  }
`

export const OptionRadio = styled.input`
  opacity: 0;
  position: absolute;

  &:focus ~ ${StyledIcon} {
    outline: 0.1rem dotted #000;
  }
  &:focus:not(:focus-visible) ~ ${StyledIcon} {
    outline: none;
  }
  &:focus-visible ~ ${StyledIcon} {
    outline: 0.1rem dotted #000;
  }
`

export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.5rem;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: grey;
  margin-top: 0.5rem;
`
