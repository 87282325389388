import styled, { css } from 'styled-components'

import ActionButton from '../../../atoms/ActionButton'
import FormLabel from '../../FormLabel'

export const Container = styled.div<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
    `}
`

export const Label = styled(FormLabel)``

export const EmptyText = styled.p`
  ${(props) => props.theme.textStyles.textSmall};
  color: grey;
  margin-top: 16px;
`

export const ImagePreview = styled.img`
  margin-top: 16px;
  width: 200px;
  display: block;
`

export const UploadButton = styled(ActionButton)<{
  $hasValue?: boolean
  $isDisabled?: boolean
}>`
  margin-top: 16px;
  display: ${(props) => (props.$hasValue ? 'none' : 'initial')};
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
    `};
`

export const DeleteButton = styled(ActionButton)<{
  $hasValue?: boolean
  $isDisabled?: boolean
}>`
  margin-top: 16px;
  display: ${(props) => (props.$hasValue ? 'initial' : 'none')};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
    `};
`
