import styled from 'styled-components'
import { PatternFormat } from 'react-number-format'
import { InputStyleProps } from '@storybook/components'

import Icon from '../../../atoms/Icon'
import { inputStyle } from '../FormFieldInput/styled'
import FormFieldInput from '../FormFieldInput'

export const Input = styled(FormFieldInput)``

export const SelectContainer = styled.div<{ $isVisible?: boolean }>`
  position: relative;
  top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.pureBlack};
  opacity: ${({ $isVisible }) => ($isVisible === true ? 1 : 0)};
`

export const Select = styled.select`
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  //width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  position: relative;
  padding-right: 2rem;
  z-index: 1;
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.bismark};
  &:focus {
    outline: 0.1rem dotted #000;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:focus-visible {
    outline: 0.1rem dotted #000;
  }
`

export const DropDown = styled(Icon)`
  width: 1.5rem;
  position: relative;
  left: -1.5rem;
`

export const InputComponent = styled(PatternFormat)<InputStyleProps>`
  ${inputStyle}
`
