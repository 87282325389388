import { FormFieldUploadImageProps } from './index'

const basic: FormFieldUploadImageProps = {
  name: 'name',
  label: 'Label',
  uploadButton: {
    text: 'Ajouter une image',
  },
  deleteButton: {
    text: 'Supprimer',
  },
  emptyText: 'Aucune image sélectionnée',
}

const required: FormFieldUploadImageProps = {
  ...basic,
  required: true,
}
const disabled: FormFieldUploadImageProps = {
  ...basic,
  disabled: true,
}

const error: FormFieldUploadImageProps = {
  ...basic,
  error: 'error',
}

export const formFieldUploadImageMocks = {
  basic,
  required,
  disabled,
  error,
}
