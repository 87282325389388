import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js'

import { Icons } from '../../../atoms/Icon'
import { FormFieldInputProps } from '../FormFieldInput'

import * as SC from './styled'

export type Country = {
  value: string
  flag?: string
  prefix: string
  label?: string
}

export type FormFieldPhoneProps = Omit<FormFieldInputProps, 'value'> & {
  selectName?: string
  value?: string
  countryLabel?: string
  numberLabel?: string
  onValidityChange?: (isValid: boolean) => void
  countries: Country[]
  onCountryChange?: (countryValue?: string) => void
  defaultCountry?: string
}

const FormFieldPhone: FC<FormFieldPhoneProps> = (props) => {
  const {
    selectName = 'country',
    label,
    name,
    value,
    onChange,
    onValidityChange,
    countries,
    defaultCountry,
    onCountryChange,
    ...extraProps
  } = props
  const [hasFocused, setHasFocused] = useState(false)
  const [realValue, setRealValue] = useState(null)
  const isActive = !label || hasFocused || !!realValue

  const parsedPhone: any = useMemo(() => {
    try {
      return parsePhoneNumber(value ?? '')
    } catch (_e) {
      return {}
    }
  }, [value])

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(parsedPhone?.isValid?.() ?? false)
    }
  }, [onValidityChange, parsedPhone])

  const [country, setCountry] = useState(
    (parsedPhone?.country &&
      countries?.find((c) => c.value === parsedPhone?.country)) ||
      (defaultCountry && countries?.find((c) => c.value === defaultCountry)) ||
      countries?.[0]
  )

  const textFieldValue = useMemo(() => {
    return value?.replace(country?.prefix, '')?.replace(/^0+/, '')
  }, [value, country])

  const handleChangeCountry = useCallback(
    (c?: Country) => {
      setCountry(c)
      onCountryChange?.(c?.value)
      onChange?.({
        target: {
          name,
          value: c?.prefix ?? '' + value?.replace(c?.prefix ?? '', ''),
        },
      } as ChangeEvent<HTMLInputElement>)
    },
    [name, onChange, value, onCountryChange]
  )
  const mask = useMemo(() => {
    const ayt = new AsYouType(country?.name as CountryCode)
    ayt.input(value ?? '')
    const tpl: string = ayt.getTemplate()?.replace(/x+ /, '')
    return tpl
      ?.split('')
      ?.concat('x')
      ?.map((char) => (char === 'x' ? '#' : char))
      .join('')
  }, [value, country])

  return (
    <SC.Input
      {...extraProps}
      withChildFocused={hasFocused}
      leftAdornment={
        <SC.SelectContainer
          $isVisible={
            isActive || (value?.replace(country?.prefix, '') ? true : false)
          }
        >
          <SC.Select
            name={selectName}
            value={country?.value}
            onFocus={() => setHasFocused(true)}
            onBlur={() => setHasFocused(false)}
            onChange={(v) =>
              handleChangeCountry(
                countries.find((c) => c.value === v.currentTarget.value)
              )
            }
          >
            {countries?.map((option, index) => (
              <option value={option.value} key={index}>
                {option.flag ? option.flag + ' ' : '' + option.prefix}
              </option>
            ))}
          </SC.Select>
          <SC.DropDown icon={Icons.chevronDown} />
        </SC.SelectContainer>
      }
      label={label}
      name={name}
      value={textFieldValue}
      onFocus={(e) => {
        setHasFocused(true)
        extraProps?.onFocus?.(e)
      }}
      onBlur={(e) => {
        setHasFocused(false)
        extraProps?.onBlur?.(e)
      }}
      InputComponent={SC.InputComponent}
      inputProps={{
        onValueChange: (values: any) => {
          setRealValue(
            values?.value?.replace(/[^0-9]+/g, '')?.replace(/^0+/, '')
          )
          onChange?.({
            target: {
              name,
              value:
                country?.prefix +
                values?.value?.replace(/[^0-9]+/g, '')?.replace(/^0+/, ''),
            },
          } as ChangeEvent<HTMLInputElement>)
        },
        format: mask,
      }}
    />
  )
}

export default FormFieldPhone
